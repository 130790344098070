import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const isSSR = typeof window === 'undefined'
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  })

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}


export const setUtmParams = () => {
  const params = new URLSearchParams(window.location.search);

  return {
    fullURL: window.location.href,
    utmSource: params.get('utm_source'),
    utmMedium: params.get('utm_medium'),
    utmCampaign: params.get('utm_campaign'),
    utmTerm: params.get('utm_term'),
    utmContent: params.get('utm_content'),
    fbclid: params.get('fbclid'),
    gclid: params.get('gclid')
  }
}

export const areUtmParamsAvailable = (params) => {
  return params.utmSource || params.utmMedium || params.utmCampaign || params.utmTerm || params.utmContent || params.fbclid || params.gclid
}