import React from 'react';
import tw from 'twin.macro';

const Spinner = () => {
  return (
    <div css={[tw`flex justify-center items-center`]}>
      <div css={[tw`animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#FF4D00]`]}></div>
    </div>
  );
};

export default Spinner;
