import React from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export const HeaderXLStyle = tw`font-mont font-bold text-[48px] line-height[52.8px] lg:(text-[80px] line-height[88px])`
export const HeaderTextStyle = tw`font-mont font-bold text-[48px] line-height[52.8px] lg:(text-[60px] line-height[65px]) xl:(text-[80px] line-height[88px])`
export const HeaderLGStyle = tw`font-mont font-bold text-[36px] line-height[39.6px] lg:(text-[50px] line-height[55px])`
export const HeaderStyle = tw`font-mont font-bold text-[28px] line-height[30.8px] lg:(text-[36px] line-height[39.6px])`
export const SubheadLGStyle = tw`font-mont font-semibold text-[24px] line-height[26.4px] lg:(text-[24px] line-height[26.4px])`
export const SubheadStyle = tw`font-mont font-semibold text-[21px] line-height[23.1px] lg:(text-[24px] line-height[26.4px])`
export const SubheadSMStyle = tw`font-mont font-semibold text-[18px] line-height[19.8px]`
export const CopyLGStyle = tw`font-mont font-normal text-[21px] line-height[30.45px]`
export const CopyStyle = tw`font-mont font-normal text-[16px] line-height[23.2px]`
export const CopySMStyle = tw`font-mont font-normal text-[12px] line-height[17.4px]`
export const ButtonStyle = tw`font-mont font-medium text-[15px] line-height[19.2px]`
export const FAQStyle = tw`font-mont font-medium text-[16px] line-height[19.2px]`
export const ScoreStyle = tw`font-mont font-bold text-[80px] line-height[88px] lg:(text-[50px] line-height[55px])`
export const CardInfoStyle = tw`font-mont font-normal text-[12px] line-height[17.4px] lg:(text-[16px] line-height[23.2px])`

export const LinkButton = ({ children, destination, external }) =>
  external ? (
    <a
      href={destination}
      target="_blank"
      rel="noreferrer"
      css={[
        ButtonStyle,
        tw`inline-block py-4 px-8 w-full text-center border-[#FF4D00] border-[1.5px] lg:(px-8 w-auto)`,
        tw`transition-all transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:(text-white bg-[#FF4D00])`,
      ]}
    >
      {children}
    </a>
  ) : (
    <Link
      to={destination}
      css={[
        ButtonStyle,
        tw`inline-block py-4 px-8 w-full text-center border-[#FF4D00] border-[1.5px] lg:(px-8 w-auto)`,
        tw`transition-all transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:(text-white bg-[#FF4D00])`,
      ]}
    >
      {children}
    </Link>
  )

export const LinkButtonOrange = ({ children, external }) =>
  <button
    css={[
      ButtonStyle,
      tw`inline-block py-3 px-8 text-center text-white bg-[#FF4D00] text-[14px] lg:(px-12 w-auto py-4 px-12 text-[16px])`,
      tw`transition-all transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)]`,
    ]}
  >
    {children}
  </button>


export const Button = ({ children, onClick }) => (
  <button
    css={[
      ButtonStyle,
      tw`inline-block py-4 px-8 w-full text-center border-[#FF4D00] border-[1.5px] lg:(px-8 w-auto)`,
    ]}
    onClick={onClick}
  >
    {children}
  </button>
)

export const Container = ({ children }) => (
  <div tw="relative mx-7 lg:(max-w-screen-lg mx-auto)">{children}</div>
)

export const BlogSubhead = ({ children }) => (
  <h2 css={[SubheadStyle, tw`mb-5 lg:(mb-8)`]}>{children}</h2>
)

export const BlogCopy = ({ children }) => (
  <p css={[CopyStyle, tw`mb-8 lg:(mb-12)`]}>{children}</p>
)

export const BlogImage = ({ image }) => (
  <GatsbyImage tw="w-full mb-2.5" image={image} alt="" />
)

export const BlogImageCaption = ({ children }) => (
  <div css={[CopySMStyle, tw`mb-8 lg:(mb-14)`]}>{children}</div>
)

export const TitleLinkButton = ({ children, destination, external }) => {
  return external ? (
    <a
      href={destination}
      target="_blank"
      rel="noreferrer"
      css={[
        tw`hover:(text-[#FF4D00] underline)`,
      ]}
    >
      {children}
    </a>
  ) : (
    <Link
      to={destination}
      css={[
        tw`hover:(text-[#FF4D00] underline)`,
      ]}
    >
      {children}
    </Link>
  )
}